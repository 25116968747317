









































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { IParticipant } from '@/interface/participant';
import { IProject } from '@/interface/project';
import { GROUP, IGroup } from '@/interface/group';
import { EMAIL } from '@/interface/email';

@Component
export default class InvitationBoardSend extends Vue {
  @PropSync('invitationTargetList') invitationList: IParticipant[];
  @PropSync('project') selectProject: IProject;
  @PropSync('group') selectGroup: IGroup;
  GROUP: any = GROUP;
  emailHtml: string = '';

  async openConfirmInvitation() {
    console.log(this.selectProject, this.selectGroup);
    if (!this.selectGroup) {
      this.$toast.error('예정 중인 조사를 선택 후 미리보기가 가능합니다.');
      return;
    }

    const { data } = await this.axios.get(`/mail/template/${EMAIL.TEMPLATE_TYPE.BOARD_INVITATION}`);
    const { emailHtml } = data;
    this.emailHtml = emailHtml;
    this.$bvModal.show('invitationConfirmModal');
  }

  openInvitationModal() {
    this.$bvModal.show('invitationModal');
  }

  closeInvitationModal() {
    this.$bvModal.hide('invitationModal');
  }

  closeInvitationConfirmModal() {
    this.$bvModal.hide('invitationConfirmModal');
  }

  async invitationSend() {
    try {
      if (!this.selectGroup) {
        this.$toast.error('예정 중인 조사를 선택 후 발송이 가능합니다.');
        return;
      }
      const { title: projectTitle, _id: projectId } = this.selectProject;
      const { title: groupTitle, _id: groupId } = this.selectGroup;

      const ok = await this.$bvModal.msgBoxConfirm(
        `[${projectTitle} (${groupTitle})] 게시판 초대발송을 하시겠습니까?`,
        {
          title: '발송 확인',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: '발송',
          cancelTitle: '취소',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!ok) return;

      //#TODO : TYPE 정의
      const type = 'board';
      const sendData = {
        invitationList: this.invitationList,
        projectId,
        groupId,
        type,
      };

      const { data } = await this.axios.post(`/invite`, sendData);
      const { result } = data;
      if (result) {
        this.$toast.success(
          `${this.$validation.replaceComma(this.invitationList.length)} 건의 게시판 초대메일이 발송되었습니다.`
        );
        this.closeInvitationModal();
      }
    } catch (e) {
      console.log(e);
    }
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }
}
