






































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { IParticipant, PARTICIPANT } from '@/interface/participant';

@Component
export default class InviteParticipantsFooterCard extends Vue {
  @PropSync('checkedParticipants') syncCheckParticipants: IParticipant[];
  @Prop({type: String}) groupId: string;
  participants: IParticipant[] = [];

  created() {
    if(!this.groupId) return;
    this.load()
  }

  async load() {
    try {
      const {data} = await this.axios({
        url: `group-participant/${this.groupId}`
      });
      const {list} = data;

      if(list.length) {
        this.participants = list.map(item=> {
          const status = item.status === PARTICIPANT.INVITATION_STATUS.COMPLETED? 'success': 'warning'
          return {
            status,
            ...item,
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  get getParticipants(): IParticipant[]{
    this.syncCheckParticipants = this.participants.filter(v=>v.checked)
    console.log('1');
    return this.participants;
  }


}
