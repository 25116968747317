import { render, staticRenderFns } from "./InvitationBoardSend.vue?vue&type=template&id=baa95f2e&scoped=true&"
import script from "./InvitationBoardSend.vue?vue&type=script&lang=ts&"
export * from "./InvitationBoardSend.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa95f2e",
  null
  
)

export default component.exports