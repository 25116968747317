






















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ISurvey, SURVEY } from '@/interface/survey';
import { IProject, PROJECT } from '@/interface/project';
import { IParticipant } from '@/interface/participant';
import { GROUP, IGroup } from '@/interface/group';
import InvitationBoardSend from '../../components/project/InvitationBoardSend.vue';
import InviteParticipantsFooterCard from '@/components/invite/InviteParticipantsFooterCard.vue';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';
import GROUP_STATUS = GROUP.GROUP_STATUS;

@Component({
  components: {
    TopShortcutLeft,
    InviteParticipantsFooterCard,
    InvitationBoardSend,
  },
})
export default class List extends Vue {
  pending: boolean = false;
  search: string = '';
  status: PROJECT.PROJECT_STATUS = PROJECT.PROJECT_STATUS.EXPECT;
  projectArray: Array<IProject> = [];
  groupInfo: IGroup = GROUP.getInitGroup();
  sort: number = -1;
  page: number = 1;
  limitPage: number = 10;
  totalRow: number = 0;
  PROJECT: any = PROJECT;
  GROUP: any = GROUP;
  SURVEY: any = SURVEY;
  selectProject: IProject = PROJECT.getInitProject();
  projectStatusTabIndex: number = 0;
  checkedParticipants: IParticipant[] = [];
  $refs!: Vue['$refs'] & {
    InvitationBoardSend: InvitationBoardSend;
  };
  question: ISurvey = SURVEY.getInitSurvey();

  async resetGroupInfo(): Promise<void> {
    this.groupInfo = GROUP.getInitGroup();
    this.question = SURVEY.getInitSurvey();
  }

  async created() {
    await this.projectList();
  }

  closeGroupModal(): void {
    this.checkedParticipants = [];
    this.$bvModal.hide('createGroupModal');
  }

  replaceGroupStatus(status: string): string {
    const option = GROUP.GROUP_OPTIONS.find((opt) => status == opt.value);
    return option?.text || '';
  }

  async openGroupInfo(projectIndex: number, groupIndex: number): Promise<void> {
    this.selectProject = this.projectArray[projectIndex];
    const { groups } = this.selectProject;
    if (groups == undefined) {
      this.$toast.error(`선택된 그룹이 없습니다. `);
      return;
    }
    await this.openCreateGroupModal(projectIndex);
    this.groupInfo = {
      ...groups[groupIndex],
    };
    const { participants } = this.groupInfo;
    this.groupInfo.participants = participants?.map((p: IParticipant) => {
      return {
        ...p,
        checked: true,
      };
    });
  }

  async projectList(): Promise<void> {
    this.pending = true;
    try {
      const sendData = {
        status: this.status,
        page: this.page,
        limitPage: this.limitPage,
        search: this.search,
        sort: this.sort,
        additionalFeatures: GROUP.ADDITIONAL_FEATURES.BOARD,
      };
      const { data } = await this.axios.get(`/project`, { params: sendData });
      const { projects, total } = data;
      this.totalRow = total;
      this.projectArray = projects.map((p: IProject) => {
        return {
          groupShow: false,
          ...p,
        };
      });
    } catch (e) {
      console.log(e);
    }
    this.pending = false;
  }

  async getGroupInfos() {
    console.log();
    // this.projectList()
  }

  async openCreateGroupModal(index: number) {
    await this.resetGroupInfo();
    this.selectProject = {
      ...this.projectArray[index],
    };
    const { interviewType } = this.selectProject;
    this.groupInfo.sampleSize = GROUP.SAMPLE_SIZE.find((item) => item.interviewType === interviewType)?.min || 0;
    this.$bvModal.show('createGroupModal');
  }

  getGroupStatus(status: GROUP.GROUP_STATUS): string {
    const find = GROUP.GROUP_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }

  getProjectStatus(status: PROJECT.PROJECT_STATUS): string {
    const find = PROJECT.PROJECT_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }

  groupStatusColor(status: GROUP.GROUP_STATUS): string {
    if (status == GROUP.GROUP_STATUS.EXPECT) return 'dark';
    else if (status == GROUP.GROUP_STATUS.END) return 'danger';
    else if (status == GROUP.GROUP_STATUS.PROGRESS) return 'info';
    else if (status == GROUP.GROUP_STATUS.COMPLETED) return 'success';
    else return `secondary`;
  }

  projectStatusColor(status: string): string {
    if (status == '대기') return 'warning';
    else if (status == '예정') return 'dark';
    else if (status == '진행') return 'info';
    else if (status == '완료') return 'success';
    else return `secondary`;
  }

  projectStatusChange(tab: number): void {
    if (tab == 0) this.status = PROJECT.PROJECT_STATUS.EXPECT;
    else if (tab == 1) this.status = PROJECT.PROJECT_STATUS.PROGRESS;
    else if (tab == 2) this.status = PROJECT.PROJECT_STATUS.COMPLETED;
    this.projectList();
  }

  sortChange() {
    this.sort = this.sort * -1;
    this.page = 1;
    this.projectList();
  }

  statusDisabled(groupObjectId: string = ''): boolean {
    let status = '';
    if (groupObjectId) {
      const listGroup = this.selectProject.groups?.find((p) => p._id == groupObjectId);
      const { status: selectStatus } = listGroup || { status: '' };
      status = selectStatus || '';
    } else {
      const { status: selectStatus } = this.groupInfo;
      status = selectStatus || '';
    }

    if (status === GROUP_STATUS.COMPLETED || status === GROUP_STATUS.END) return true;
    else return false;
  }

  boardInvitation() {
    // this.checkedParticipants =
    // this.groupInfo.participants?.filter((p: any) => p.checked === true) || [];
    if (this.checkedParticipants.length < 1) {
      this.$toast.error(`게시판 초대할 참여인원을 선택해주세요.`);
      return;
    } else {
      this.$refs.InvitationBoardSend.openInvitationModal();
    }
  }
}
